/** shared vars **/
$font: raleway, sans-serif;
/* greys */
$txt: #808080;
$label: #999;
/* background colours */
$light-grey: #b3b3b3;
$tint: #f2f2f2;

/* colours */
$primary: #0f3959; /* navy */
$highlight: #f37321; /* orange */
$highlight-dark: #f04923;
$secondary: #98c03d; /* green */
$tertiary: #ede834; /* yellowy-greeny */

@mixin button { /* links and form buttons */
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1;
    padding: 11px 20px;
    padding-right: 40px;
    max-width: 100%;
    color: #FFF !important;
    background-color: $highlight;
    border: 2px solid #FFF;
    box-sizing: border-box;
    border-radius: 21px;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: $secondary;
    }
}
@mixin gradient($c1: $highlight, $c2: $highlight-dark){
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,1e5799+100 */
    background: $c1; /* Old browsers */
    background: -moz-linear-gradient(left,  $c1 0%, $c2 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  $c1 0%,$c2 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  $c1 0%, $c2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
