@import 'common';

body {
    font-family: $font;
    font-size: 18px;
    line-height: 1.2;
    color: $txt;
    font-style: normal;
    font-weight: 400;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
h1, h2, h3, h4 {
    color: $highlight;
}
h1, h2 {
    font-size: 2.6em;
}
h3 {
    font-size: 1.6em;
}
.clear-float {
    clear:both;
}

.blue {
    color: $primary;
}
.orange {
    color: $highlight;
}

.button {
    @include button;
}

/** image **/
img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}
img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}
@media only screen and (max-width: 400px){
/** normalise font-size **/
    h1, h2 {
        font-size: 1.6em;
    }
    h3 {
        font-size: 1.17em;
    }
}